/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import { useLocation } from '@reach/router';
import theme from '@theme';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import {
  Footer,
  ChatSatisfi,
  ChatTrackhs,
  OverlayWrapper,
} from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useWindowResize } from '@powdr/hooks';
import { componentFactory, isEmpty } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import SEO from '../components/seo';
import GlobalStyle from '../utils/global-styles';

const SiteWrapper = styled.div``;
const StyledInnerPage = styled.div``;
const StyledMain = styled.main`
  ${({ offset }) => (offset === 0 ? '' : `margin-top: ${offset}px`)}
`;

// TODO: look into breaking out some components from layout and
// here so we don't have to repeat any code and can wrap the useEffects around a component
// to simplify things
function Error404PageTemplate({ data }) {
  if (!data || !theme) {
    return (
      <div>404 Page not found. Admin has not setup custom 404 page.</div>
    );
  }

  const uri = '/404';
  const currentPage = data.pageContent.nodes?.[0];
  const resize = useWindowResize();
  const { href } = useLocation();
  const {
    headerConfig,
    defaultSeason,
    chatComponent,
  } = useStaticData();
  const {
    defaultTheme,
    isMobile,
    setIsMobile,
    fullHeaderHeight,
    alertHeight,
  } = useContext(AppContext);

  const { title } = currentPage;

  useEffect(() => {
    setIsMobile(!!(resize.windowWidth < 960));
  }, [resize.windowWidth, setIsMobile]);

  const levels = useMemo(() => uri.split('/').filter((level) => level !== ''), [uri]);

  return (
    <>
      <GlobalStyle theme={defaultTheme} />
      <SEO
        title={title}
        url={href}
        currentPage={currentPage}
        levels={levels}
        defaultSeason={defaultSeason}
      />
      <SiteWrapper>
        <OverlayWrapper
          levels={levels}
          showStickyNav={false}
          isPageModal
        />
        <StyledMain
          offset={isMobile || (!isMobile && headerConfig.navigationOpacityPercentage === 100)
            ? fullHeaderHeight : alertHeight}
        >
          <StyledInnerPage>
            {componentFactory(currentPage?.relationships.pageContent || [])}
          </StyledInnerPage>
        </StyledMain>
        <Footer
          partners={data.allTaxonomyTermPartners.nodes}
          footerLinks={data.allTaxonomyTermFooterLinks.nodes}
          colorProfile={theme.config?.footer}
        />

        {/* Chat Component widgets */}
        {(chatComponent.hasChatComponent) && (
          <>
            {!isEmpty(chatComponent.chatSatisfi) && (
              <ChatSatisfi {...chatComponent.chatSatisfi} />
            )}
            {!isEmpty(chatComponent.chatTrackhs) && (
              <ChatTrackhs {...chatComponent.chatTrackhs} />
            )}
          </>
        )}
      </SiteWrapper>
    </>
  );
}

Error404PageTemplate.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

Error404PageTemplate.defaultProps = {

};

export default Error404PageTemplate;

export const query = graphql`
  query Error404PageTemplate {
    site {
      ...SiteMetadataQuery
    }
    pageContent: allNodePage(filter: {field_page_location: {eq: "/404"}}) {
      ...NodePageQuery
    }
    allTaxonomyTermFooterLinks {
      ...TaxonomyTermFooterLinksQuery
    }
    allTaxonomyTermPartners {
      ...TaxonomyTermPartnersQuery
    }
    paragraphHeaderConfiguration {
      ...HeaderConfigurationQuery
    }
    nestedNavigation: taxonomyTermSiteNavigation {
      ...NestedSiteNavigationQuery
    }
    pageSettings: allTaxonomyTermSiteNavigation(filter: {field_navigation_link: {eq: "/404"}}) {
      nodes {
        ...NavigationItemQuery
      }
    }
  }
`;
